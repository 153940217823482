import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { api_call, console_log } from '../../helpers/untils';
import { setSettingPersist } from '../../helpers/misc';

const OemPage = () => {
  const onClickSmartcarSignup = async () => {
    const response = await api_call(`/api/smartcar/get-login-url`);
    if (response.ok) {
      const res = await response.json()
      console_log("smartcar res:::", res)
      setSettingPersist('smartcar_auth_source', 'signup')
      window.location.href = res.link
      //history.push('/smartcar-device')
    } else {
      window.alert("Smartcar authentication error")
    }
  }

  useEffect(() => {
    onClickSmartcarSignup()
  }, []);

  return (
    <div style={{ display: 'none' }}>
      <Button onClick={() => onClickSmartcarSignup()} color="secondary">
        OEM Signup
      </Button>
    </div>
  );
};

export default OemPage;
