import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import { browserName, browserVersion } from "react-device-detect";

import {
  Grid, useMediaQuery, makeStyles, InputLabel, Select, MenuItem, FormControl, Button, TextField,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sessionActions } from '../../store';
import { useLocalization, useTranslation } from '../../LocalizationProvider';
import StartPage from '../../StartPage';
import usePersistedState from '../../common/usePersistedState';
import { api_call, console_log, empty, getToken, get_utc_timestamp_ms, setToken } from '../../helpers/untils';
import { GOOGLE_CHROME_LOGO, GOOGLE_CHROME_URL, MACHINE_ENVIRONMENT, RECAPTCHA_ENABLED, RECAPTCHA_KEY } from '../../config/constant';
import { clearPaypalBanner, getSettingPersist, setSettingPersist } from '../../helpers/misc';
import CaptchaBox from '../CaptchaBox';
import LoginAgreeModal from './LoginAgreeModal';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  resetPassword: {
    cursor: 'pointer',
  },
  chromeLogoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#e5e5e5',
    borderRadius: '4px',
    overflow: 'hidden',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.5)',
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: '4px',
  },
  chromeLogoImg: {
    width: 'calc(100% - 210px)',
    height: 'auto'
  },
  chromeLogoTxt: {
    flex: 1,
    paddingLeft: '0.5rem',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: '700',
    color: '#333333',
  },
}));

const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const userRef = useRef()
  const [showAgreeModal, setShowAgreeModal] = useState(false);

  const [failed, setFailed] = useState(false);

  const [email, setEmail] = usePersistedState('loginEmail', (MACHINE_ENVIRONMENT === 'dev' ? 'quanseng632@gmail.com' : ''));
  const [password, setPassword] = useState(MACHINE_ENVIRONMENT === 'dev' ? '123456' : '');

  ///////////// start recaptcha part //////////////////////////////////////////////////////////////////////////
  const captchaKey = 'captcha_login'
  const [recaptcha, setRecaptcha] = useState(RECAPTCHA_ENABLED === "false" ? "xxxxxxxxxxxxxxxxx" : "");
  const [captchaTimestamp, setCaptchaTimestamp] = useState(get_utc_timestamp_ms());
  const updateCaptchaSettingEnabled = (value) => {
    const oldValue = getSettingPersist(captchaKey)
    if (true || oldValue !== value) { // refresh every attempt
      setSettingPersist(captchaKey, value)
      setCaptchaTimestamp(get_utc_timestamp_ms())
    }
  }
  ///////////// end recaptcha part //////////////////////////////////////////////////////////////////////////

  const handleSubmit = async (event) => {
    event.preventDefault();
    const rand = () => Math.floor((1 + Math.random()) * 0x100000000).toString(16).substring(1);
    const session = rand();
    window.localStorage.setItem('session', session);
    var data = { email: email, password: password, session: session };
    const response = await api_call('/api/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`),
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const user = await response.json();
      console_log("user::::", user);
      updateCaptchaSettingEnabled(false)

      const isNewUser = user['isNewUser']
      if (isNewUser) {
        userRef.current = user
        setShowAgreeModal(true)
      } else {
        processAfterLogin(user)
      }

    } else {
      updateCaptchaSettingEnabled(true)

      setFailed(true);
      setPassword('');
    }
  }

  const processAfterLogin = (user) => {
    setToken(user.token)
    dispatch(sessionActions.updateUser(user));

    let shouldAddDevice = true; // should go to "add new device" page
    if (user['administrator']) {
      shouldAddDevice = false;
    }
    const userDetail = user['userDetail'];
    if (userDetail) {
      const userDevices = userDetail['devices']
      console_log("userDevices::::", userDevices);
      if (!empty(userDevices) && userDevices.length > 0) {
        shouldAddDevice = false;
      }
    }
    const userCoHostDeviceIds = user['userCoHostDeviceIds'];
    if (userCoHostDeviceIds && userCoHostDeviceIds.length > 0) {
      shouldAddDevice = false;
    }

    updateCaptchaSettingEnabled(false)

    if (shouldAddDevice) {
      history.push('/device');
      window.location.href = '/device';
    } else {
      window.location.href = '/main';
    }
  }

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password && recaptcha) {
      handleSubmit(e);
    }
  };

  const [isChrome, setIsChrome] = useState(true);

  useEffect(() => {
    // window.localStorage.removeItem('session');
    // dispatch(sessionActions.updateUser(null));
    console.log("browserName", browserName)
    if (browserName.indexOf("Chrome") > -1) {
      setIsChrome(true);
    } else {
      setIsChrome(false);
    }

    checkLoggedin()
  }, []);

  const checkLoggedin = async () => {
    const m_token = getToken();
    if (m_token) {
      window.location.href = '/main';
    }
  }

  const applyLogout = async () => {
    const m_token = getToken();
    if (m_token) {
      const url = `/api/auth/${m_token}`
      const response = await api_call(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setToken("")
    }
  }

  const onClickAgree = () => {
    //console.log('onClickAgree::::')
    handleAgreeSubmit()
    //setShowAgreeModal(false)

  }

  const onClickCancel = () => {
    setShowAgreeModal(false)
  }

  const handleAgreeSubmit = async () => {
    const user = userRef.current
    var data = { token: user.token };
    const response = await api_call('/api/auth-agree', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      processAfterLogin(user)
    } else {
      window.alert("Invalid request!")
    }
  }

  const onClickSmartcarSignup = async () => {
    history.push('/oem-signup')

    // const response = await api_call(`/api/smartcar/get-login-url`);
    // if (response.ok) {
    //   const res = await response.json()
    //   console_log("smartcar res:::", res)
    //   setSettingPersist('smartcar_auth_source', 'signup')
    //   window.location.href = res.link
    //   //history.push('/smartcar-device')
    // } else {
    //   alert("Smartcar authentication error")
    // }
  }

  return (
    <StartPage>
      <Grid container direction="column" spacing={2}>
        {useMediaQuery(theme.breakpoints.down('md'))
          && (
            <Grid item className={classes.logoContainer}>
              <Link to="/">
                <img height="85" width="240" src="/logo.png" alt="Mobile Logo Login" />
              </Link>
              {
                (isChrome) ? (<></>) : (
                  <a href={GOOGLE_CHROME_URL} target="_blank" className={classes.chromeLogoContainer}>
                    <img src={GOOGLE_CHROME_LOGO} className={classes.chromeLogoImg} alt="Mobile Logo Chrome" />
                    <span className={classes.chromeLogoTxt}>Get Google Chrome</span>
                  </a>
                )
              }
            </Grid>
          )}
        <Grid item>
          <TextField
            required
            fullWidth
            error={failed}
            label={t('userEmail')}
            name="email"
            value={email}
            autoComplete="email"
            autoFocus={!email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={handleSpecialKey}
            helperText={failed && 'Invalid username or password'}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            error={failed}
            label={t('userPassword')}
            name="password"
            value={password}
            type="password"
            autoComplete="current-password"
            autoFocus={!!email}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={handleSpecialKey}
            variant="filled"
          />
        </Grid>
        {
          (RECAPTCHA_ENABLED === "true") && (
            <Grid item>
              <CaptchaBox
                recaptcha={recaptcha}
                setRecaptcha={setRecaptcha}
                captchaKey={captchaKey}
                captchaTimestamp={captchaTimestamp}
              />
            </Grid>
          )
        }

        <Grid item>
          <Button
            onClick={handleSubmit}
            onKeyUp={handleSpecialKey}
            variant="contained"
            color="secondary"
            disabled={!email || !password || !recaptcha}
            fullWidth
          >
            {t('loginLogin')}
          </Button>
        </Grid>
        <Grid container spacing={1}>
          <Grid item style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {/* <Button onClick={() => history.push('/register')} color="secondary">
              {t('loginRegister')}
            </Button> */}
            <Button onClick={() => history.push('/reset-password')} className={classes.resetPassword} underline="none" color="secondary">{t('loginReset')}</Button>
            <Button onClick={() => history.push('/installer-login')} color="secondary">
              Installer Login
            </Button>
          </Grid>

          <Grid item style={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingTop: 0 }}>
            <Button onClick={() => onClickSmartcarSignup()} color="secondary">
              OEM Signup
            </Button>
            <div></div>
          </Grid>

        </Grid>
        {/* {emailEnabled && ( */}
        {/* <Grid item container justify="flex-end">
          <Grid item>
            <Link onClick={() => history.push('/reset-password')} className={classes.resetPassword} underline="none">{t('loginReset')}</Link>
          </Grid>
        </Grid> */}
        {/* )} */}
      </Grid>

      {
        (showAgreeModal) && (
          <>
            <LoginAgreeModal
              open={showAgreeModal}
              setOpen={setShowAgreeModal}
              onClickAgree={onClickAgree}
              onClickCancel={onClickCancel}
            />
          </>
        )
      }
    </StartPage>
  );
};

export default LoginForm;
