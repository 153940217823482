import React, { useEffect, useState } from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid, Typography, Link, Box, FormControlLabel, Checkbox, makeStyles
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from '../LocalizationProvider';
import { console_log, convertUnixTimestampToTimezone, empty, getLocalTimezone, getTimezoneList, getUnixTimestampFromTimezone, getUtcFromTimezone, is_null } from '../helpers/untils';
import ShareSettingForm from './shareSettingForm';
import TuroCalendarInputModal from './TuroCalendarInputModal';
import { Stack } from '@mui/material';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { getTuroSummaryFirstLastWord } from '../helpers/misc';

const TIMEZONE_LIST = getTimezoneList()

const useStyles = makeStyles((theme) => ({
  formRowInlineController: {
    flex: 1
  },
}));

const ShareFilter = (props) => {
  const { children, handleSubmit, showOnly, selectedDeviceId, setSelectedDeviceId, selectedDevice, tripList, trip, setTrip, snackbarOpen, setSnackbarOpen, toastMessage, setToastMessage, history, shareUrl, apiLoading, setApiLoading, pageData, setPageData, generatedUrl, showToast, copyTextClipboard, onClickSMS, onClickVoice, usedTripIds = [] } = props

  const t = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.session.user);

  const devices = useSelector((state) => Object.values(state.devices.items));
  const [deviceId, setDeviceId] = useState(selectedDeviceId);
  const [from, setFrom] = useState(moment());
  const [to, setTo] = useState(moment().add(1, 'day'));

  const defaultFormData = {
    share_minutes_before: 60,
    share_minutes_after: 30,
    allot_miles: '',
    allot_per_day: 200,
    auto_lock: false,
    auto_send_toll: false,
    auto_send_toll_email: user?.email,
    daily_send_toll: false,
  }
  const [formData, setFormData] = useState(defaultFormData)

  useEffect(() => {
    initDefaultSettings()
  }, [selectedDevice])

  const initDefaultSettings = () => {
    //console.log("selectedDevice::::", selectedDevice)
    if (selectedDevice) {
      let shareSettings = selectedDevice?.share_settings
      if (!empty(shareSettings)) {
        shareSettings = JSON.parse(shareSettings)
      } else {
        shareSettings = {}
      }
      //console.log("shareSettings:::::", shareSettings)
      if (shareSettings['share_minutes_before']) {
        shareSettings['share_minutes_before'] = parseInt(shareSettings['share_minutes_before'])
      }
      if (shareSettings['share_minutes_after']) {
        shareSettings['share_minutes_after'] = parseInt(shareSettings['share_minutes_after'])
      }
      shareSettings['auto_lock'] = (shareSettings['auto_lock'] === "true")
      shareSettings['auto_send_toll'] = (shareSettings['auto_send_toll'] === "true")
      shareSettings['daily_send_toll'] = (shareSettings['daily_send_toll'] === "true")

      const _form_data = {
        ...defaultFormData,
        ...shareSettings
      }
      setFormData(_form_data)

      if (_form_data['timezone']) {
        const timezoneOption = {
          id: _form_data['timezone'],
          label: _form_data['timezone']
        }
        setTimezone(timezoneOption)
      }

      onChangeFromTo(null, null, _form_data)
    }
  }

  const filter = createFilterOptions({
    stringify: (option) => option.uid + "   " + option.reservation_id + "   " + option.summary + "   " + option.primary_driver + "   " + option.phone + "   " + option.vin,
  });

  const [options, setOptions] = useState([]);
  const initOptions = () => {
    let v_options = []
    tripList.map((definition) => {
      v_options.push({
        key: definition.id,
        // uid: definition.uid,
        // reservation_id: definition.reservation_id,
        // summary: definition.summary,
        // primary_driver: definition.primary_driver,
        // phone: definition.phone,
        // vin: definition.vin,
        // start: definition.start,
        // end: definition.end,
        ...definition
      })
    });
    //console.log("tripList:::", tripList)
    //console.log("v_options:::", v_options)
    setOptions(v_options)
  }
  useEffect(() => {
    initOptions()
  }, [deviceId, tripList])

  const onChangeTrip = (option) => {
    setTrip(option);
  }

  ///////////////////////////////////////////////////////
  const localTimezone = getLocalTimezone()
  const defaultTimezoneOption = {
    id: localTimezone,
    label: localTimezone
  }
  const [timezone, setTimezone] = useState(null);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const initTimezoneOptions = () => {
    let v_options = []
    TIMEZONE_LIST.map((definition) => {
      v_options.push({
        id: definition,
        label: definition,
      })
    });
    //console.log("v_options:::", v_options)
    setTimezoneOptions(v_options)
    setTimezone(defaultTimezoneOption)
  }
  useEffect(() => {
    initTimezoneOptions()
  }, [])
  const timezoneFilter = createFilterOptions({
    stringify: (option) => option.key + "   " + option.label,
  });

  const getTimezoneLabel = (timezoneInfo, returnType = "text") => {
    if (returnType === "object") {
      return (
        <div>
          <div>{`${timezoneInfo.label}`}</div>
        </div>
      )
    } else {
      return timezoneInfo.label
    }
  }

  const onChangeTimezone = (option) => {
    console.log("onChangeTimezone::::", option)
    setTimezone(option);
  }

  const handleClick = async (form_data) => {
    if (from >= to) {
      alert("Please ensure `To` is greater than `From`")
      return false
    }

    form_data.append("deviceId", deviceId);
    const iana_timezone = timezone?.label ?? localTimezone
    form_data.append("timezone", iana_timezone);
    const fromUtc = getUtcFromTimezone(from.format('YYYY-MM-DD HH:mm:ss'), iana_timezone)
    form_data.append("from", fromUtc.toISOString());
    form_data.append("from_local", from.format('YYYY-MM-DD HH:mm:ss'));
    const toUtc = getUtcFromTimezone(to.format('YYYY-MM-DD HH:mm:ss'), iana_timezone)
    form_data.append("to", toUtc.toISOString());
    form_data.append("to_local", to.format('YYYY-MM-DD HH:mm:ss'));

    form_data.append("share_minutes_before", formData['share_minutes_before']);
    form_data.append("share_minutes_after", formData['share_minutes_after']);
    form_data.append("allot_miles", formData['allot_miles']);
    form_data.append("allot_per_day", formData['allot_per_day']);
    form_data.append("auto_lock", formData['auto_lock']);
    form_data.append("auto_send_toll", formData['auto_send_toll']);
    form_data.append("auto_send_toll_email", formData['auto_send_toll_email']);
    form_data.append("daily_send_toll", formData['daily_send_toll']);

    handleSubmit(form_data)
  }

  const onChaangeDeviceId = (e) => {
    const val = e.target.value
    setDeviceId(val)
    setSelectedDeviceId(val)
  }

  const [showTuroCalendarInputModal, setShowTuroCalendarInputModal] = useState(false)
  const onClickTuroSetup = () => {
    setShowTuroCalendarInputModal(true)
  }

  const getTuroSummaryLabel = (tripInfo, returnType = "text") => {
    // const { summaryFirstWord, summaryLastWord } = getTuroSummaryFirstLastWord(tripInfo)
    // const start_timestamp = tripInfo.start_timestamp
    // const start_time = start_timestamp ? moment.unix(start_timestamp).format("YYYY-MM-DD HH:mm") : ""
    // const end_timestamp = tripInfo.end_timestamp
    // const end_time = end_timestamp ? moment.unix(end_timestamp).format("YYYY-MM-DD HH:mm") : ""
    // const label = `${summaryFirstWord} - ${summaryLastWord} \n${start_time} - ${end_time}`

    const { summaryFirstWord, summaryLastWord } = getTuroSummaryFirstLastWord(tripInfo)
    const start_timestamp = tripInfo.start_timestamp
    const start_time = start_timestamp ? convertUnixTimestampToTimezone(start_timestamp, "YYYY-MM-DD HH:mm", tripInfo.tzid) : ""
    const end_timestamp = tripInfo.end_timestamp
    const end_time = end_timestamp ? convertUnixTimestampToTimezone(end_timestamp, "YYYY-MM-DD HH:mm", tripInfo.tzid) : ""
    const label = `${summaryFirstWord} - ${summaryLastWord} \n${start_time} - ${end_time}`
    
    if (returnType === "object") {
      return (
        <div className={usedTripIds.includes(tripInfo.id) ? 'text-opacity-54' : ''}>
          <div>{`${summaryFirstWord} - ${summaryLastWord}`}</div>
          <div>{`${start_time} - ${end_time}`}</div>
        </div>
      )
    } else {
      return label
    }
  }

  const onChangeCheckBox = (event, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = event.target.checked
    setFormData(form_data)
  }
  const onChangeFormInput = (event, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = event.target.value
    setFormData(form_data)
  }

  const onChnageFrom = (e) => {
    const _from = moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    setFrom(_from)
    onChangeFromTo(_from, null)
  }
  const onChnageTo = (e) => {
    const _to = moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    setTo(_to)
    onChangeFromTo(null, _to)
  }

  const getDiffDays = (_from, _to) => {
    const fromStr = _from.format('YYYY-MM-DD HH:mm')
    const toStr = _to.format('YYYY-MM-DD HH:mm')
    console_log(`fromStr, toStr::::`, fromStr, toStr)
    const _from1 = moment(`${fromStr}:00`, 'YYYY-MM-DD HH:mm:ss')
    const _to1 = moment(`${toStr}:00`, 'YYYY-MM-DD HH:mm:ss')
    console_log(`_from1, _to1::::`, _from1, _to1)
    let diff_seconds = _to1.diff(_from1, 'seconds')
    if (diff_seconds < 0) {
      console_log(`diff_seconds::::`, diff_seconds)
      return 0
    }

    let diff_days = diff_seconds / (24 * 3600);  //let diff_days = _to1.diff(_from1, 'days')
    diff_days = Math.ceil(diff_days)

    console_log(`diff_days::::`, diff_days)
    // if (diff_days === 0) {
    //   diff_days = 1
    // }
    return diff_days
  }

  const onChnageAllot = (e) => {
    let allot_miles = e.target.value
    const form_data = { ...formData }
    form_data['allot_miles'] = allot_miles

    const _from = from
    const _to = to
    const diff_days = getDiffDays(_from, _to)
    let allot_per_day = allot_miles / diff_days
    console_log(`allot_per_day:::`, allot_per_day)
    allot_per_day = Math.floor(allot_per_day * 1000000000) / 1000000000
    form_data['allot_per_day'] = allot_per_day
    setFormData(form_data)
  }

  const onChangeFromTo = (_from, _to, _form_data = null) => {
    if (is_null(_from)) {
      _from = from
    }
    if (is_null(_to)) {
      _to = to
    }
    if (is_null(_form_data)) {
      _form_data = formData
    }
    const diff_days = getDiffDays(_from, _to)
    const allot_per_day = _form_data['allot_per_day']
    let allot_miles = diff_days * allot_per_day
    console_log(`allot_miles:::`, allot_miles)
    allot_miles = Math.ceil(allot_miles)
    setFormData({ ..._form_data, allot_miles: allot_miles })
  }

  useEffect(() => {
    if (trip) {
      const iana_timezone = timezone?.label ?? localTimezone
      let startTimestamp = getUnixTimestampFromTimezone(trip.start, trip.tzid)
      let endTimestamp = getUnixTimestampFromTimezone(trip.end, trip.tzid)
      const _from = convertUnixTimestampToTimezone(startTimestamp, "", iana_timezone)
      const _to = convertUnixTimestampToTimezone(endTimestamp, "", iana_timezone)
      setFrom(_from)
      setTo(_to)
      onChangeFromTo(_from, _to)
    } else {
      const iana_timezone = timezone?.label ?? localTimezone
      let startTimestamp = moment().unix()
      let endTimestamp = moment().add(1, 'day').unix()
      const _from = convertUnixTimestampToTimezone(startTimestamp, "", iana_timezone)
      const _to = convertUnixTimestampToTimezone(endTimestamp, "", iana_timezone)
      setFrom(_from)
      setTo(_to)
      onChangeFromTo(_from, _to)
    }
  }, [trip, timezone])

  return (
    <>
      <Grid container spacing={1} justifyContent="flex-start">
        <Grid item xs={12} sm={12} style={{ marginTop: '8px' }}>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Devices</InputLabel>
            <Select value={deviceId} onChange={(e) => onChaangeDeviceId(e)}>
              {devices.map((device) => (
                <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {
          (tripList && tripList.length > 0) ? (
            <Grid item xs={12} sm={12}>
              <Autocomplete
                onChange={(_, option) => {
                  console.log("option::::", option)
                  // setKey(option && typeof option === 'object' ? option.key : option);
                  onChangeTrip(option)
                  // if (option && option.type) {
                  //   setType(option.type);
                  // }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  // if (params.inputValue) {
                  //   filtered.push({
                  //     // key: params.inputValue,
                  //     name: params.inputValue,
                  //   });
                  // }
                  return filtered;
                }}
                options={options}
                getOptionLabel={(option) => getTuroSummaryLabel(option, "text")}
                renderOption={(option) => getTuroSummaryLabel(option, "object")}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label={`Select Turo Trip`} variant="filled" margin="normal" />
                )}
              />
            </Grid>
          ) : (
            <></>
          )
        }

        <Grid item xs={12} sm={12}>
          <Box sx={{ padding: '10px', marginBottom: '10px' }}>
            <Stack direction={`row`} alignItems={`center`} spacing={4}>
              <Typography variant='p' onClick={() => onClickTuroSetup()} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1366ff' }}>Turo Setup</Typography>
              <Stack direction={`row`} alignItems={`center`} spacing={1.5}>
                <Typography variant='p'>Allot</Typography>
                <TextField
                  variant="filled"
                  label=""
                  type="text"
                  value={formData['allot_miles']}
                  onChange={(e) => onChnageAllot(e)}
                  style={{ width: '100px' }}
                  inputProps={{ type: 'number', min: 0, placeholder: "" }}
                />
                <Typography variant='p'>Miles</Typography>
                <Tooltip
                  arrow
                  title="How many miles is included in this trip"
                >
                  <InfoIcon color="action" />
                </Tooltip>
              </Stack>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Autocomplete
            onOpen={() => {
              // setTimeout(() => {
              //   const optionEl = document.querySelector(
              //     `[class="MuiAutocomplete-popper"]`
              //   );
              //   console.log("optionEl::::",optionEl.innerHTML)
              // }, 10);

              setTimeout(() => {
                const optionEl = document.querySelector(
                  `[class="MuiAutocomplete-option"][data-option-index="${TIMEZONE_LIST.indexOf(timezone?.label ?? localTimezone)}"]`
                );
                if (optionEl) {
                  optionEl.scrollIntoView();
                }
              }, 1);
            }}
            onChange={(_, option) => {
              console.log("option::::", option)
              onChangeTimezone(option)
            }}
            filterOptions={(options, params) => {
              const filtered = timezoneFilter(options, params);
              return filtered;
            }}
            options={timezoneOptions}
            getOptionLabel={(option) => getTimezoneLabel(option, "text")}
            renderOption={(option) => getTimezoneLabel(option, "text")}
            value={timezone}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label={`Timezone`} variant="filled" margin="normal" />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            variant="filled"
            label="From"
            type="datetime-local"
            value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
            onChange={(e) => onChnageFrom(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Minutes Before"
            value={formData['share_minutes_before']}
            onChange={(e) => setFormData({ ...formData, share_minutes_before: e.target.value })}
            variant="filled"
            inputProps={{ type: 'number', min: 0, placeholder: " " }}
            placeholder=" "
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            variant="filled"
            label="To"
            type="datetime-local"
            value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
            onChange={(e) => onChnageTo(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Minutes After"
            value={formData['share_minutes_after']}
            onChange={(e) => setFormData({ ...formData, share_minutes_after: e.target.value })}
            variant="filled"
            inputProps={{ type: 'number', min: 0, placeholder: " " }}
            placeholder=" "
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Stack direction={`row`} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <FormControlLabel
              control={<Checkbox checked={formData['auto_lock'] ? true : false} onChange={(event) => onChangeCheckBox(event, 'auto_lock')} />}
              label="Auto Lock"
            />
            <Tooltip
              arrow
              title="Lock will be sent at end of trip"
            >
              <InfoIcon color="action" />
            </Tooltip>
          </Stack>
        </Grid>

        {
          (selectedDevice) ? (
            <>
              <Grid item xs={12} sm={12}>
                <ShareSettingForm
                  snackbarOpen={snackbarOpen}
                  setSnackbarOpen={setSnackbarOpen}
                  toastMessage={toastMessage}
                  setToastMessage={setToastMessage}
                  history={history}
                  selectedDeviceId={selectedDeviceId}
                  selectedDevice={selectedDevice}
                  shareUrl={shareUrl}
                  onClickSaveSetting={(form_data) => handleClick(form_data)}
                  apiLoading={apiLoading}
                  setApiLoading={setApiLoading}
                  generatedUrl={generatedUrl}
                  showToast={showToast}
                  copyTextClipboard={copyTextClipboard}
                  onClickSMS={onClickSMS}
                  onClickVoice={onClickVoice}
                >
                  <Grid item xs={12} sm={12}>
                    <Stack direction={`row`} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                      <FormControlLabel
                        control={<Checkbox checked={formData['auto_send_toll'] ? true : false} onChange={(event) => onChangeCheckBox(event, 'auto_send_toll')} />}
                        label="Auto Send Toll"
                      />
                      <TextField
                        fullWidth
                        className={classes.formRowInlineController}
                        value={formData['auto_send_toll_email']}
                        onChange={(event) => onChangeFormInput(event, 'auto_send_toll_email')}
                        label=""
                        placeholder={`Enter emails with comma separated`}
                        variant="filled"
                        inputProps={{ type: 'email' }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack direction={`row`} sx={{ justifyContent: 'flex-end', alignItems: 'center', pr: 1, mt: -1 }}>
                      <FormControlLabel
                        control={<Checkbox checked={formData['daily_send_toll'] ? true : false} onChange={(event) => onChangeCheckBox(event, 'daily_send_toll')} />}
                        label="Send Toll Report Daily"
                        labelPlacement="start"
                      />
                    </Stack>
                  </Grid>
                </ShareSettingForm>
              </Grid>
            </>
          ) : (
            <></>
          )
        }
      </Grid>

      <>
        <TuroCalendarInputModal
          showToast={(msg) => showToast(msg)}
          selectedDeviceId={selectedDeviceId}
          selectedDevice={selectedDevice}
          open={showTuroCalendarInputModal}
          setOpen={setShowTuroCalendarInputModal}
          pageData={pageData}
          setPageData={setPageData}
        />
      </>
    </>
  )
}

export default ShareFilter;
