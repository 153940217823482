import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useEffectAsync } from './reactHelper';
import {
  makeStyles, Paper, Toolbar, TextField, IconButton, Button, Box, Grid, ButtonBase, Snackbar,
} from '@material-ui/core';

import { positionsActions, devicesActions, sessionActions, layoutActions } from './store';

import { trueAuthenticated } from './SocketController';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import DevicesList from './DevicesList';
import Map, { map } from './map/Map';
import SelectedDeviceMap from './map/SelectedDeviceMap';
import AccuracyMap from './map/AccuracyMap';
import GeofenceMap from './map/GeofenceMap';
import CurrentPositionsMap from './map/CurrentPositionsMap';
import CurrentLocationMap from './map/CurrentLocationMap';
import { useTranslation } from './LocalizationProvider';
import PoiMap from './map/PoiMap';
import MapPadding from './map/MapPadding';
import { api_call, console_log, empty, get_data_value, get_utc_timestamp_ms, setShareUri, validateEmail } from './helpers/untils';
import moment from 'moment';
import TemporarySoonPage from './TemporarySoonPage';
import TemporaryBottomMenu from './components/TemporaryBottomMenu';
import { RECAPTCHA_ENABLED, RECAPTCHA_KEY } from './config/constant';
import TemporaryUploadPage from './TemporaryUploadPage';
import { getSettingPersist, setSettingPersist } from './helpers/misc';
import CaptchaBox from './components/CaptchaBox';
import { useLocation } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: 56,
    zIndex: 1301,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  paper: {
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
  },
  sidebarToggle: {
    position: 'absolute',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: '#777777',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const TemporaryMapPage = () => {
  //const current = moment();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation()
  const dispatch = useDispatch();
  const t = useTranslation();

  const { shareUrl } = useParams();

  const params = new URLSearchParams(location.search)
  const mode = params.get("mode")
  const isTest = mode === 'test' ? true : false

  const [verified, setVerified] = useState(false)
  const refVerifyField = useRef(false)

  const [authenticated, setAuthenticated] = useState(true);

  const refreshAll = async (deviceId) => {
    const responseSession = await api_call(`/api/auth`);
    if (responseSession.ok) {
      const user = await responseSession.json();
      dispatch(sessionActions.updateUser({ ...user, shareUrl }));
    } else {
      const user = { id: 0 }
      dispatch(sessionActions.updateUser({ ...user, shareUrl }));
    }

    const responseDevice = await api_call(`/api/devices/${deviceId}`);
    if (responseDevice.ok) {
      const device = await responseDevice.json();

      dispatch(devicesActions.refresh([device]));
      dispatch(devicesActions.select({ id: device.id }));
    }
    setAuthenticated(true);
    trueAuthenticated();
    //history.push("/temporary/" + shareUrl);
  }

  const [currentLink, setCurrentLink] = useState(null)
  const refCurLink = useRef({})
  const [currentDeviceId, setCurrentDeviceId] = useState(null)

  const [current, setCurrent] = useState(moment())
  const [currentTimestamp, setCurrentTimestamp] = useState(moment().unix())
  const startCounter = () => {
    setTimeout(function () {
      setCurrent(moment())
      setCurrentTimestamp(moment().unix())
      startCounter()
    }, 10000)
  }

  const [currentCommands, setCurrentCommands] = useState({})

  const loadLinkInfo = async (is_loop = false) => {
    if (shareUrl) {
      const response = await api_call(`/api/shared-links/${shareUrl}`);

      if (response.ok) {
        const link = await response.json();
        if (link && link.share_settings) {
          let share_settings_obj = JSON.parse(link.share_settings)
          let share_minutes_before = share_settings_obj.share_minutes_before
          let share_minutes_after = share_settings_obj.share_minutes_after
          let avail_upload_minutes_before = share_settings_obj.avail_upload_minutes_before
          if (empty(share_minutes_before)) {
            share_minutes_before = 0
          } else {
            share_minutes_before = parseInt(share_minutes_before)
          }
          if (empty(share_minutes_after)) {
            share_minutes_after = 0
          } else {
            share_minutes_after = parseInt(share_minutes_after)
          }
          if (empty(avail_upload_minutes_before)) {
            avail_upload_minutes_before = 0
          } else {
            avail_upload_minutes_before = parseInt(avail_upload_minutes_before)
          }
          link.share_minutes_before = share_minutes_before
          link.share_minutes_after = share_minutes_after
          link.avail_upload_minutes_before = avail_upload_minutes_before
        }
        //console.log("link:::::::::::::", link)

        //console.log("refVerifyField.current::::", refVerifyField.current)
        if (refVerifyField.current) {
          const oldUnkillApproveStatus = getApproveStatus("unkill")
          const newUnkillApproveStatus = getApproveStatus("unkill", link)
          //console.log("oldUnkillApproveStatus, newUnkillApproveStatus:::::", oldUnkillApproveStatus, newUnkillApproveStatus)
          if (oldUnkillApproveStatus !== "approved" && newUnkillApproveStatus === "approved") {
            setToastMessage("Approved!")
            setSnackbarOpen(true)
          }
        }

        setCurrentLink(link)
        refCurLink.current = link
        if (link.captchaRequired) {
          updateCaptchaSettingEnabled(true)
        } else {
          updateCaptchaSettingEnabled(false)
          if (link.email && link.share_setting_enabled) {
            setVerified(true)
            refVerifyField.current = true
          }
        }
        dispatch(layoutActions.setCurrentSharedLink(link));

        setCurrentDeviceId(link.deviceId)
        const commands = link.commands
        setCurrentCommands(commands)

        if (is_loop) {
          setTimeout(() => {
            loadLinkInfo(is_loop)
          }, 10000)
        }
      }
    } else {

    }
  }

  useEffect(() => {
    if (currentDeviceId && !empty(currentDeviceId)) {
      refreshAll(currentDeviceId);
    }
  }, [currentDeviceId])

  useEffectAsync(async () => {
    setShareUri(shareUrl)
    loadLinkInfo(true)
    startCounter()
  }, [shareUrl]);

  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));

  const [searchKeyword, setSearchKeyword] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  const handleClose = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => setCollapsed(isTablet), [isTablet]);

  ///////////// start recaptcha part //////////////////////////////////////////////////////////////////////////
  const captchaKey = 'captcha_temp_link'
  const [recaptcha, setRecaptcha] = useState(RECAPTCHA_ENABLED === "false" ? "xxxxxxxxxxxxxxxxx" : "");
  const [captchaTimestamp, setCaptchaTimestamp] = useState(get_utc_timestamp_ms());
  const updateCaptchaSettingEnabled = (value) => {
    const oldValue = getSettingPersist(captchaKey)
    if (oldValue !== value) {
      setSettingPersist(captchaKey, value)
      setCaptchaTimestamp(get_utc_timestamp_ms())
    }
  }
  ///////////// end recaptcha part //////////////////////////////////////////////////////////////////////////

  const [formData, setFormData] = useState({})
  const onChangeFormInput = (event, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = event.target.value
    setFormData(form_data)
  }
  const [apiLoading, setApiLoading] = useState(false)

  const updateLinkBorwserIp = async () => {
    const payload = {
      shareUrl: shareUrl
    }
    const response = await api_call('/api/update-shared-link-ip-browser', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const link = await response.json();
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (RECAPTCHA_ENABLED === "true" && empty(recaptcha)) {
      alert("You are not a robot?")
      return false
    }

    const linkEmail = currentLink.email
    if (linkEmail) {
      setVerified(true)
      refVerifyField.current = true
      updateLinkBorwserIp()
    }
    else {
      const email = formData['email']
      if (email) {
        if (!validateEmail(email)) {
          setToastMessage("Please enter a valid email address")
          setSnackbarOpen(true)
          return false
        }
      } else { // if empty email
        setVerified(true)
        refVerifyField.current = true
        updateLinkBorwserIp()
        return true
      }

      const payload = {
        email: email,
        shareUrl: shareUrl,
      }
      setApiLoading(true)
      const response = await api_call('/api/link-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      setApiLoading(false)
      if (response.ok) {
        const link = await response.json();
        const link_info = ({ ...refCurLink.current, ...link })
        setCurrentLink(link_info)
        refCurLink.current = link_info

        console_log("link info:::", link)
        if (link && !empty(link.id)) {
          refreshAll(link.deviceId);
        }
        setVerified(true)
        refVerifyField.current = true
        updateLinkBorwserIp()

      } else {
        const errorRes = response
        setToastMessage(errorRes.error)
        setSnackbarOpen(true)
      }
    }
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  const getApproveStatus = (type = "avail", current_link = null) => {
    if (empty(current_link)) {
      current_link = refCurLink.current
    }
    let approve_status = "denied"
    if (current_link) {
      if (current_link.share_settings && current_link.share_setting_enabled) {
        const share_settings_obj = JSON.parse(current_link.share_settings)
        if (share_settings_obj[type + '_car']) {
          if (share_settings_obj[type] && share_settings_obj[type].length > 0) {
            const photo_uploads = current_link.photo_uploads
            let photo_uploads_obj = {}
            if (photo_uploads) {
              photo_uploads_obj = JSON.parse(photo_uploads)
            }
            let approve_key = type + "_approved"
            approve_status = get_data_value(photo_uploads_obj, approve_key, "denied")
          } else {
            approve_status = "approved"
          }
        } else {
          approve_status = "approved"
        }
      } else {
        approve_status = "approved"
      }
    } else {
      approve_status = "denied"
    }
    return approve_status
  }

  const linkVerifyForm = () => {
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: '100%', maxWidth: '320px', margin: 'auto' }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={1}>
              {
                (currentLink.email || !currentLink.share_setting_enabled) ? (<></>) : (
                  <>
                    <Grid item>
                      <TextField
                        fullWidth
                        value={get_data_value(formData, 'email')}
                        onChange={(event) => onChangeFormInput(event, 'email')}
                        label=""
                        variant="filled"
                        placeholder='Enter email address'
                        inputProps={{ type: 'email' }}
                      />
                    </Grid>
                  </>
                )
              }

              {
                (RECAPTCHA_ENABLED === "true") && (<>
                  <Grid item>
                    <CaptchaBox
                      recaptcha={recaptcha}
                      setRecaptcha={setRecaptcha}
                      captchaKey={captchaKey}
                      captchaTimestamp={captchaTimestamp}
                    />
                  </Grid>
                </>)
              }

              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"

                  disabled={apiLoading}
                  fullWidth
                >
                  Go
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={1500}
        message={toastMessage}
      />

      {
        (authenticated) ? (
          <>
            {
              (!empty(currentLink)) ? (
                <>
                  {
                    (moment(currentLink.from).subtract(currentLink.share_minutes_before, 'minutes') < current && current < moment(currentLink.to).add(currentLink.share_minutes_after, 'minutes')) ? (
                      <>
                        {
                          (!verified) ? (
                            <>
                              {
                                linkVerifyForm()
                              }
                            </>
                          ) : (
                            <>
                              {
                                (currentLink.share_setting_enabled && getApproveStatus() !== "approved") ? (
                                  <>
                                    <TemporaryUploadPage
                                      link={currentLink}
                                      setLink={setCurrentLink}
                                      current_timestamp={currentTimestamp}
                                      type="avail"
                                      approve_status={getApproveStatus()}
                                      loadLinkInfo={loadLinkInfo}
                                      isTest={isTest}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Map>
                                      {/* {!isTablet && <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />} */}
                                      {/* <CurrentLocationMap />
                                      <GeofenceMap />
                                      <AccuracyMap /> */}
                                      <CurrentPositionsMap
                                        temporaryPage={true}
                                        currentLink={currentLink}
                                        loadLinkInfo={loadLinkInfo}
                                        commands={currentCommands}
                                        isTest={isTest}
                                      />
                                      <SelectedDeviceMap temporaryPage={true} />
                                      <PoiMap />
                                    </Map>

                                    <TemporaryBottomMenu deviceId={currentLink.deviceId} />
                                  </>
                                )
                              }
                            </>
                          )
                        }
                      </>
                    ) : (moment(currentLink.from).subtract(currentLink.share_minutes_before, 'minutes') < current && moment(currentLink.to).add(currentLink.share_minutes_after, 'minutes') < current) ? (
                      <>
                        {/* old (expired link page) */}
                        <TemporarySoonPage
                          pageType="old"
                          link={currentLink}
                          current_timestamp={currentTimestamp}
                        />
                      </>
                    ) : (current < moment(currentLink.from).subtract(currentLink.share_minutes_before, 'minutes') && current < moment(currentLink.to).add(currentLink.share_minutes_after, 'minutes')) ? (
                      <>
                        {
                          (currentLink.share_setting_enabled && getApproveStatus() !== "approved" && moment(currentLink.from).subtract(currentLink.avail_upload_minutes_before, 'minutes') < current) ? (
                            <>
                              {
                                (!verified) ? (
                                  <>
                                    {
                                      linkVerifyForm()
                                    }
                                  </>
                                ) : (
                                  <>
                                    <TemporaryUploadPage
                                      link={currentLink}
                                      setLink={setCurrentLink}
                                      current_timestamp={currentTimestamp}
                                      type="avail"
                                      approve_status={getApproveStatus()}
                                      loadLinkInfo={loadLinkInfo}
                                      isTest={isTest}
                                    />
                                  </>
                                )
                              }
                            </>
                          ) : (
                            <>
                              {/* feature link page */}
                              <TemporarySoonPage
                                pageType="feature"
                                link={currentLink}
                                current_timestamp={currentTimestamp}
                              />
                            </>
                          )
                        }
                      </>
                    ) : (
                      <></>
                    )
                  }
                </>
              ) : (
                <></>
              )
            }
          </>
        ) : (
          <div></div>
        )
      }
    </div>
  )
}

export default TemporaryMapPage
