import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  makeStyles, withStyles, Paper, Grid, Button,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import SignalCellular0BarIcon from '@material-ui/icons/SignalCellular0Bar';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import LinearProgress from "@material-ui/core/LinearProgress";
import { styled } from "@material-ui/styles";
import { Snackbar } from '@material-ui/core';

import {
  formatPosition, formatDate
} from '../common/formatter';
import { useAttributePreference } from '../common/preferences';
import RemoveDialog from '../RemoveDialog';
import { getPosition } from '../common/selectors';
import { useTranslation } from '../LocalizationProvider';
import moment from 'moment';
import { api_call, empty, getDeviceDistanceUnit, getDeviceSpeedUnit, getDistanceFromMiles, getSpeedFromMiles, removeItemFromArray } from '../helpers/untils';
import TemporaryUploadModal from './TemporaryUploadModal';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '300px',
  },
  ...theme.palette.colors,
  listItemContainer: {
    // maxWidth: '280px',
  },
  controlBtn: {
    background: 'rgba(76, 175, 80, 0.1)',
    border: '2px solid rgba(76, 175, 80, 1)',
    marginBottom: 10,
    // paddingTop: 3,
    // paddingBottom: 3,
  },
  headerBox: {
    paddingRight: 0,
  },
  navButton: {
    border: '2px solid rgba(76, 175, 80, 1)',
  },
  tempStatusViewContainer: {
    '& .MuiListItem-gutters': {
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }
  }
}));

const MyListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    },
    "&$selected:hover": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    },
    "&:hover": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    }
  },
  selected: {}
})(ListItem);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  width: 150,
  borderRadius: 5,
}));

const TemporaryStatusView = (props) => {
  const {
    currentLink, loadLinkInfo, deviceId, onShowDetails, onShowHistory, onShareLink, onEditClick, address, onLogClick, commandsData, userDeviceItem, containerLayout = "map", isTest = false
  } = props

  //console.log("currentLink:::", currentLink);
  //console.log("commandsData:::", commandsData);
  const classes = useStyles();
  const t = useTranslation();

  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const device = useSelector((state) => state.devices.items[deviceId]);
  const position = useSelector(getPosition(deviceId));


  const user = useSelector((state) => state.session.user);
  const currentSharedLink = useSelector((state) => state.layout.currentSharedLink);
  //console.log("currentSharedLink:::", currentSharedLink)

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
  };

  const [commandsDetail, setCommandsDetail] = useState(commandsData);

  const handleSendCommand = async () => {
    var url = `/api/commandsDetail/${deviceId}`;
    const response = await api_call(url);
    if (response.ok) {
      setCommandsDetail(await response.json());
    }
  };

  useEffect(() => {
    handleSendCommand()
  }, []);

  const watchCurrentLinkStatus = async () => {
    if (currentSharedLink && currentSharedLink.share_setting_enabled) {
      let share_settings_obj = null
      if (currentSharedLink.share_settings) {
        share_settings_obj = JSON.parse(currentSharedLink.share_settings)
      }
      let photo_uploads_obj = null
      if (currentSharedLink.photo_uploads) {
        photo_uploads_obj = JSON.parse(currentSharedLink.photo_uploads)
      }
      // console.log("share_settings_obj, photo_uploads_obj:::", share_settings_obj, photo_uploads_obj)
      if (share_settings_obj) {
        if (share_settings_obj.unlock_car) {
          if (share_settings_obj.unlock && share_settings_obj.unlock.length > 0) {
            if (photo_uploads_obj) {
              if (photo_uploads_obj.unlock_approved !== "approved") {
                if (link.unlock_approved !== "denied" && photo_uploads_obj.unlock_approved === "denied") {
                  setUploadType("unlock")
                }
                return false
              }
            }
          }
        }

        if (share_settings_obj.auto_kill_switch) {
          if (share_settings_obj.unkill_car && share_settings_obj.unkill_by !== 'renter') {
            if (share_settings_obj.unkill && share_settings_obj.unkill.length > 0) {
              if (photo_uploads_obj) {
                if (empty(photo_uploads_obj.unkill_approved) || photo_uploads_obj.unkill_approved === 'denied') {
                  setUploadType("unkill")
                  return false
                }
              }
            }
          }
        }
      }
    }
  }

  const [link, setLink] = useState(currentLink)

  useEffect(() => {
    watchCurrentLinkStatus()
    setLink(currentSharedLink)
  }, [currentSharedLink]);

  //////////////////////////////////////////////////////////
  const commands = ['Unlock', 'Lock', 'Horn'];
  const commandLabels = {
    Unlock: "Unlock",
    Lock: "Lock",
    Horn: "Honk Horn",
  }

  const [uploadType, setUploadType] = useState("")

  const [loadingCommands, setLoadingCommands] = useState([])

  const popLoadingCommand = (value) => {
    let loading_commands = [...loadingCommands]
    if (value === 'Horn') {
      setTimeout(() => {
        loading_commands = removeItemFromArray(loading_commands, value)
        //console.log("loading_commands::::", loading_commands)
        setLoadingCommands(loading_commands)
      }, 30 * 1000)
    } else {
      loading_commands = removeItemFromArray(loading_commands, value)
      //console.log("loading_commands::::::::::", loading_commands)
      setLoadingCommands(loading_commands)
    }
  }

  const handleListItemClick = async (value) => {
    let msg = "Sent Command"
    if (loadingCommands.includes(value)) {
      setNotificationMessage("Please try later");
      setNotificationShow(true);
      return false
    }
    setLoadingCommands([...loadingCommands, value])
    const response = await api_call(`/api/command?command=${value}&deviceId=${device.id}&userId=${currentLink.userId}&shareUrl=${currentLink.shareUrl}`);

    popLoadingCommand(value)

    if (response.ok) {
      msg = "Sent Command"
    } else {
      msg = "Failed sending command"
      const errorObj = response
      if (errorObj) {
        if (errorObj.errorType) {
          msg = errorObj.error
          if (errorObj.errorType === 'upload_unlock_approval') {
            setUploadType("unlock")
            return false
          }
          else if (errorObj.errorType === 'upload_unkill_approval') {
            setUploadType("unkill")
            return false
          }
        }
        else if (errorObj.error) {
          msg = errorObj.error
        }
      }
      setNotificationMessage(msg);
      setNotificationShow(true);
      return false
    }
    await handleSendCommand()
    //setNotificationMessage(msg);
    //setNotificationShow(true);
  }

  const getDetail = (command) => {
    // var tempCommand = command;
    // if(command === "Kill/Unkill") tempCommand = "Kill";
    for (var key in commandsDetail) {
      if (key === command) {
        if (key === "Overspeed") return commandsDetail[key].count + getDeviceSpeedUnit(device?.distance_unit);
        else if (key === "Geofence") return commandsDetail[key].count + getDeviceDistanceUnit(device?.distance_unit);
        return formatDate(commandsDetail[key].createdAt);
      }
    }
    return false;
  }
  const getStatus = (command) => {
    return commandsDetail[command].status;
  }

  const [notificationShow, setNotificationShow] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  return (
    <>
      {
        (
          <div className='status-view-popup' style={{ maxHeight: 'calc(100vh - 165px)', overflowY: 'auto' }}>
            <Paper className={classes.paper} elevation={0} square>
              <Grid container direction="column" className={classes.tempStatusViewContainer}>
                <Grid item>
                  <List>
                    <ListItem className={classes.headerBox}>
                      <ListItemText primary={device.name} />
                      <Button color="secondary" className={classes.navButton} style={{ width: '115px', marginRight: '-8px' }}
                        onClick={() => { window.open(`https://www.google.com/maps/dir/your+location/${position.latitude},${position.longitude}/@${position.latitude},${position.longitude},17z`, '_blank'); }}>
                        Go To Car
                      </Button>
                    </ListItem>

                    <div>
                      {(commandsDetail) && commands.map((command) => (
                        <MyListItem button onClick={() => handleListItemClick(command)} key={command} className={classes.controlBtn} disabled={loadingCommands.includes(command)}>
                          <ListItemText primary={<div className='text-center center'>{commandLabels[command].toUpperCase()}</div>} />
                          {getDetail(command) &&
                            (
                              getStatus(command) === 'success' ?
                                <DoneAllIcon color="secondary" /> :
                                <CheckIcon color="disabled" />
                            )
                          }
                        </MyListItem>
                      ))}
                    </div>

                    {
                      (containerLayout === "map") ? (
                        <div>
                          <Grid container>
                            <Grid item>
                              <Button color="secondary" onClick={() => { onShowHistory(deviceId) }}>Replay</Button>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        <></>
                      )
                    }

                    <ListItem classes={{ container: classes.listItemContainer }}>
                      <ListItemText primary="Signal" />
                      {(moment().diff(moment(device.lastConnect), "seconds") < 600) && <>
                        {
                          device.signal === 0 && <SignalCellular0BarIcon /> ||
                          device.signal <= 8 && <SignalCellular1BarIcon /> ||
                          device.signal <= 16 && <SignalCellular2BarIcon /> ||
                          device.signal <= 24 && <SignalCellular3BarIcon /> ||
                          device.signal <= 31 && <SignalCellular4BarIcon />
                        }
                        {/* <span className={classes[getStatusColor(device.status)]}>{device.status}</span> */}
                      </>}
                    </ListItem>

                    <ListItem classes={{ container: classes.listItemContainer }}>
                      <ListItemText primary="Last Position" />
                      <>
                        {formatDate(device.lastPosition)}
                      </>
                    </ListItem>

                    <ListItem classes={{ container: classes.listItemContainer }}>
                      <ListItemText primary="Last Connect" />
                      <>
                        {formatDate(device.lastConnect)}
                      </>
                    </ListItem>

                    <ListItem classes={{ container: classes.listItemContainer }}>
                      {/* <ListItemText primary="Address" /> */}
                      <ListItemSecondaryAction>
                        <div style={{ marginRight: '-8px' }}>{address}</div>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem classes={{ container: classes.listItemContainer }}>
                      <ListItemText primary={t('positionSpeed')} />
                      {(moment().diff(moment(device.lastPosition), "seconds") < 60) && <>
                        {getSpeedFromMiles(position.speed, device.distance_unit)}
                      </>}
                    </ListItem>
                    <ListItem classes={{ container: classes.listItemContainer }}>
                      <ListItemText primary="Odometer" />
                      <>
                        {getDistanceFromMiles(Number(currentLink.end_odometer) - Number(currentLink.start_odometer), device.distance_unit)}
                      </>
                    </ListItem>
                    <ListItem classes={{ container: classes.listItemContainer }}>
                      <ListItemText primary={t('positionDirection')} />
                      <>
                        {formatPosition(position.direction, 'direction', t)}
                      </>
                    </ListItem>
                    {/* <div className='mt-2'></div> */}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </div>
        )
      }

      {
        (uploadType !== "") && (
          <>
            <TemporaryUploadModal
              open={uploadType !== ""}
              setOpen={setUploadType}
              link={currentLink}
              type={uploadType}
              loadLinkInfo={loadLinkInfo}
              isTest={isTest}
            />
          </>
        )
      }

      <RemoveDialog open={removeDialogShown} endpoint="devices" itemId={deviceId} onResult={handleRemoveResult} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={notificationShow}
        message={notificationMessage}
        autoHideDuration={1500}
        onClose={() => { setNotificationShow(false) }}
      />
    </>

  );
};

export default TemporaryStatusView;
