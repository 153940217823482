import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_SORT_KEY, DEFAULT_SORT_ORDER, SORT_KEY_LIST } from '../config/constant';
import { getSettingPersist } from '../helpers/misc';
import { get_utc_timestamp_ms } from '../helpers/untils';

const { reducer, actions } = createSlice({
  name: 'layout',
  initialState: {
    deviceListMenu: 'open', //open, close
    clearCache: false,
    forceLogin: false,
    currentDeviceId: 0,
    deviceReloadTimestamp:0,
    currentSharedLink:null,
    appModalCloseTimestamp: 0,
    currentDeviceSortKey: getSettingPersist("main_device_sort_key", DEFAULT_SORT_KEY),
    currentDeviceSortOrder: getSettingPersist("main_device_sort_order", DEFAULT_SORT_ORDER),
    paymentVideoModalTimestamp: 0, 
 
  },
  reducers: {
    openDeviceListMenu(state, action) {
      //action.payload.id;
      state.deviceListMenu = "open"
    },
    closeDeviceListMenu(state, action) {
      //action.payload.id;
      state.deviceListMenu = "close"
    },  
    clearCache(state, action) {
      state.clearCache = true
    },  
    clearCacheWithLogin(state, action) {
      state.clearCache = true
      state.forceLogin = true
    },  
    revokeClearCache(state, action) {
      state.clearCache = false
      state.forceLogin = false
    },
    setCurrentDeviceId(state, action) {
      //action.payload.id;
      //console.log("action.payload", action.payload)
      state.currentDeviceId = action.payload.id
    },
    setDeviceReloadTimestamp(state, action) {
      state.deviceReloadTimestamp = action.payload.value
    },
    setCurrentSharedLink(state, action) {
      state.currentSharedLink = action.payload
    },
    closeAllAppModal(state, action) {
      state.appModalCloseTimestamp = get_utc_timestamp_ms()
    },  
    setCurrentDeviceSortKey(state, action) {
      state.currentDeviceSortKey = action.payload
    },
    setCurrentDeviceSortOrder(state, action) {
      state.currentDeviceSortOrder = action.payload
    },
    showPaymentVideoModal(state, action) {
      state.paymentVideoModalTimestamp = get_utc_timestamp_ms()
    },  
  },
});

export { actions as layoutActions };
export { reducer as layoutReducer };
